import Vue from 'vue'
import VueRouter from 'vue-router'
import header from '@/common/header.vue'
import flowHeader from '@/common/header/flow_header.vue'
import Home from '@/components/home.vue'
import login from '@/components/logo/login.vue'
import register from '@/components/logo/register.vue' // 注册
import registration from '@/components/logo/registration.vue' // 注册协议
import disclaimer from '@/components/logo/disclaimer.vue' // 免责声明
import confidentiality from '@/components/logo/confidentiality.vue' // 隐私政策
import forgetPassword from '@/components/logo/forgetPassword.vue' // 忘记密码
import notFound from '@/components/status/notFound.vue'
import serverError from '@/components/status/serverError.vue'
import information from '@/components/filled/information.vue' //基本信息
import softIndex from '@/components/filled/softIndex.vue' //软性指标
import yearForecast from '@/components/filled/yearForecast.vue' // 财务指标   年预测界面
import carryingValue from '@/components/filled/carryingValue.vue' // 财务指标   账面净值
import incomeForecast from '@/components/filled/incomeForecast.vue' // 财务指标   收入预测表
import payment from '@/components/filled/payment.vue' //  估值报告

import examineFinancialIndex from '@/components/getInfo/examineFinancialIndex.vue' // 查看信息  财务指标

import reportDV from '@/components/report/reportDV.vue' // 数据估值报告
import report from '@/components/report/report.vue' // 数据估值报告



// 数据估值（快速）
import dataValuationInfoFast from '@/views/dataValuation/fast/information.vue' //基本信息
import dataValuationSoftFast from '@/views/dataValuation/fast/softIndex.vue' //软性指标
import dataValuationLiabilitiesFast from '@/views/dataValuation/fast/balanceSheet.vue' //资产负债表
import dataValuationProfitFast from '@/views/dataValuation/fast/profitStatement.vue' //利润表
import dataResourceFast from '@/views/dataValuation/fast/dataResourceFast.vue' //数据资源
import dataValuationIAFast from '@/views/dataValuation/fast/intangibleAssets.vue' //组合无形资产-数据资源贡献程度
import dataValuationPIAFast from '@/views/dataValuation/fast/portfolioIntangibleAssets.vue' //组合无形资产贡献程度
import dataValuationPaymentFast from '@/views/dataValuation/fast/payment.vue' //预支付页面
import dataValuationAuditFast from '@/views/dataValuation/fast/getInfo/examineAuditStatus.vue' //查看信息  审核状态
import dataValuationEInfoFast from '@/views/dataValuation/fast/getInfo/examineInformation.vue' // 查看信息  基本信息
import dataValuationESoftFast from '@/views/dataValuation/fast/getInfo/examineSoftInformation.vue' // 查看信息  软性指标
import dataValuationEFinancialFast from '@/views/dataValuation/fast/getInfo/examineFinancialIndex.vue' // 查看信息  财务指标
import dataValuationEContributionFast from '@/views/dataValuation/fast/getInfo/examineContribution.vue' // 查看信息  贡献程度
import dataValuationReportFast from '@/views/dataValuation/fast/report.vue' // 数据估值快速报告

// 数据估值（专业）
import dataInfoSpecialty from '@/views/dataValuation/specialty/information.vue' //基本信息
import dataSoftSpecialty from '@/views/dataValuation/specialty/softIndex.vue' //软性指标
import dataLiabilitiesSpecialty from '@/views/dataValuation/specialty/balanceSheet.vue' //资产负债表
import dataProfitSpecialty from '@/views/dataValuation/specialty/profitStatement.vue' //利润表
import dataAssetSpecialty from '@/views/dataValuation/specialty/dataAsset.vue' //数据资产
import dataIncomeForecast from '@/views/dataValuation/specialty/incomeForecast.vue' //收入预测
import dataNetProfitForecast from '@/views/dataValuation/specialty/netProfitForecast.vue' //净利润预测
import dataAccountingPolicy from '@/views/dataValuation/specialty/accountingPolicy.vue' // 会计政策统计表(选填) 

import dataAuditSpecialty from '@/views/dataValuation/specialty/getInfo/examineAuditStatus.vue' //查看信息  审核状态
import memberbasicTitle from '@/views/dataValuation/specialty/getInfo/examineInformation.vue' // 查看信息  基本信息
import viewSoftIndicators from '@/views/dataValuation/specialty/getInfo/examineSoftInformation.vue' // 查看信息  软性指标
import viewContributionLevel from '@/views/dataValuation/specialty/getInfo/examineContribution.vue' // 查看信息  贡献程度
import examineAssetLiability from '@/views/dataValuation/specialty/getInfo/examineAssetLiability.vue' // 查看信息 资产负债表
import examineProfit from '@/views/dataValuation/specialty/getInfo/examineProfit.vue' // 查看信息 利润表
import examineDataAsset from '@/views/dataValuation/specialty/getInfo/examineDataAsset.vue' // 查看信息 数据资产
import examineIncomeForecast from '@/views/dataValuation/specialty/getInfo/examineIncomeForecast.vue' // 查看信息 收入预测
import examineNetProfitForecast from '@/views/dataValuation/specialty/getInfo/examineNetProfitForecast.vue' // 查看信息 净利润预测
import examineAccountingPolicy from '@/views/dataValuation/specialty/getInfo/examineAccountingPolicy.vue' // 查看信息 会计政策统计表
import dataReportSpecialty from '@/views/dataValuation/specialty/report.vue' // 专业版报告 


import dataIASpecialty from '@/views/dataValuation/specialty/intangibleAssets.vue' //组合无形资产-数据资源贡献程度
import dataPIASpecialty from '@/views/dataValuation/specialty/portfolioIntangibleAssets.vue' //组合无形资产贡献程度
import dataPaymentSpecialty from '@/views/dataValuation/specialty/payment.vue' //预支付页面

// 数据评价
import basicInformationEvaluation from '@/views/dataEvaluation/filled/information.vue' //数据评价（基本信息）
import evaluationSoftIndicators from '@/views/dataEvaluation/filled/softIndex.vue' //数据评价（软性指标）
import evaluationValuationReport from '@/views/dataEvaluation/filled/payment.vue' //数据评价（估值报告）
import examineAuditStatus from '@/components/getInfo/examineAuditStatus.vue' // 查看信息  审核状态
import examineInformation from '@/components/getInfo/examineInformation.vue' // 查看信息  基本信息
import examineSoftInformation from '@/components/getInfo/examineSoftInformation.vue' // 查看信息  软性指标
import reportEvaluation from '@/views/dataEvaluation/report/reportEvaluation.vue' // 数据评价报告
import evaluationCertificate from '@/views/dataEvaluation/report/evaluationCertificate.vue' // 数据评价证书



const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
	return originalPush.call(this, location).catch(err => err)
}

Vue.use(VueRouter)

const routes = [{
		path: '/',
		redirect: '/home'
	},
	{
		path: '/home',
		name: 'Home',
		component: Home,
		meta: {
			title: '数据估值',
			content: {
				keywords: "心流,心流慧估,企业价值评估,股权评估,数据估值,重组并购,公司估值,大数据",
				description: "心流网，心流慧估金融科技提供企业价值评估、股权评估、公司估值、数据估值、重组并购大数据查询。心流自主研发曼殊估值专家系统，依托互联网、运用大数据和人工智能技术为企业股权估值。"
			}
		},

	},
	{
		path: '/login',
		name: 'login',
		component: login,
		meta: {
			title: '登录'
		}
	}, {
		path: '/register',
		name: 'register',
		component: register,
		meta: {
			title: "注册"
		}
	}, {
		path: '/registration',
		name: 'registration',
		component: registration,
		meta: {
			title: "心流网注册协议"
		}
	}, {
		path: '/disclaimer',
		name: 'disclaimer',
		component: disclaimer,
		meta: {
			title: "免责声明"
		}
	}, {
		path: '/confidentiality',
		name: 'confidentiality',
		component: confidentiality,
		meta: {
			title: "隐私政策"
		}
	}, {
		path: '/forgetPassword',
		name: 'forgetPassword',
		component: forgetPassword,
		meta: {
			title: "忘记密码"
		}
	}, {
		path: '/notFound',
		name: 'notFound',
		component: notFound,
		meta: {
			title: '404错误'
		}
	}, {
		path: '/serverError',
		name: 'serverError',
		component: serverError,
		meta: {
			title: '500错误'
		}
	},
	{
		path: '/information',
		name: 'information',
		component: information,
		meta: {
			title: '基本信息',
			content: {
				keywords: "心流,心流慧估,企业价值评估,股权评估,重组并购,公司估值,大数据",
				description: "心流网，心流慧估金融科技提供企业价值评估、股权评估、公司估值、重组并购大数据查询。心流自主研发曼殊估值专家系统，依托互联网、运用大数据和人工智能技术为企业股权估值。"
			}
		}
	},
	{
		path: '/softIndex',
		name: 'softIndex',
		component: softIndex,
		meta: {
			title: '软性指标',
			content: {
				keywords: "心流,心流慧估,企业价值评估,股权评估,重组并购,公司估值,大数据",
				description: "心流网，心流慧估金融科技提供企业价值评估、股权评估、公司估值、重组并购大数据查询。心流自主研发曼殊估值专家系统，依托互联网、运用大数据和人工智能技术为企业股权估值。"
			}
		}
	},
	{
		path: '/yearForecast',
		name: 'yearForecast',
		component: yearForecast,
		meta: {
			title: '财务指标',
			content: {
				keywords: "心流,心流慧估,企业价值评估,股权评估,重组并购,公司估值,大数据",
				description: "心流网，心流慧估金融科技提供企业价值评估、股权评估、公司估值、重组并购大数据查询。心流自主研发曼殊估值专家系统，依托互联网、运用大数据和人工智能技术为企业股权估值。"
			}
		}
	},
	{
		path: '/carryingValue',
		name: 'carryingValue',
		component: carryingValue,
		meta: {
			title: '账面净值',
			content: {
				keywords: "心流,心流慧估,企业价值评估,股权评估,重组并购,公司估值,大数据",
				description: "心流网，心流慧估金融科技提供企业价值评估、股权评估、公司估值、重组并购大数据查询。心流自主研发曼殊估值专家系统，依托互联网、运用大数据和人工智能技术为企业股权估值。"
			}
		}
	},
	{
		path: '/incomeForecast',
		name: 'incomeForecast',
		component: incomeForecast,
		meta: {
			title: '收入预测表',
			content: {
				keywords: "心流,心流慧估,企业价值评估,股权评估,重组并购,公司估值,大数据",
				description: "心流网，心流慧估金融科技提供企业价值评估、股权评估、公司估值、重组并购大数据查询。心流自主研发曼殊估值专家系统，依托互联网、运用大数据和人工智能技术为企业股权估值。"
			}
		}
	},
	{
		path: '/payment',
		name: 'payment',
		component: payment,
		meta: {
			title: '估值报告',
			content: {
				keywords: "心流,心流慧估,企业价值评估,股权评估,重组并购,公司估值,大数据",
				description: "心流网，心流慧估金融科技提供企业价值评估、股权评估、公司估值、重组并购大数据查询。心流自主研发曼殊估值专家系统，依托互联网、运用大数据和人工智能技术为企业股权估值。"
			}
		}
	},
	{
		path: '/examineAuditStatus',
		name: 'examineAuditStatus',
		component: examineAuditStatus,
		meta: {
			title: '审核状态',
			content: {
				keywords: "心流,心流慧估,企业价值评估,股权评估,重组并购,公司估值,大数据",
				description: "心流网，心流慧估金融科技提供企业价值评估、股权评估、公司估值、重组并购大数据查询。心流自主研发曼殊估值专家系统，依托互联网、运用大数据和人工智能技术为企业股权估值。"
			}
		}
	},
	{
		path: '/examineInformation',
		name: 'examineInformation',
		component: examineInformation,
		meta: {
			title: '基本信息',
			content: {
				keywords: "心流,心流慧估,企业价值评估,股权评估,重组并购,公司估值,大数据",
				description: "心流网，心流慧估金融科技提供企业价值评估、股权评估、公司估值、重组并购大数据查询。心流自主研发曼殊估值专家系统，依托互联网、运用大数据和人工智能技术为企业股权估值。"
			}
		}
	},
	{
		path: '/examineSoftInformation',
		name: 'examineSoftInformation',
		component: examineSoftInformation,
		meta: {
			title: '软性指标',
			content: {
				keywords: "心流,心流慧估,企业价值评估,股权评估,重组并购,公司估值,大数据",
				description: "心流网，心流慧估金融科技提供企业价值评估、股权评估、公司估值、重组并购大数据查询。心流自主研发曼殊估值专家系统，依托互联网、运用大数据和人工智能技术为企业股权估值。"
			}
		}
	},
	{
		path: '/examineFinancialIndex',
		name: 'examineFinancialIndex',
		component: examineFinancialIndex,
		meta: {
			title: '财务指标',
			content: {
				keywords: "心流,心流慧估,企业价值评估,股权评估,重组并购,公司估值,大数据",
				description: "心流网，心流慧估金融科技提供企业价值评估、股权评估、公司估值、重组并购大数据查询。心流自主研发曼殊估值专家系统，依托互联网、运用大数据和人工智能技术为企业股权估值。"
			}
		}
	},
	{
		path: '/reportDV',
		name: 'reportDV',
		component: reportDV,
		meta: {
			title: '数据估值报告',
			content: {
				keywords: "心流,心流慧估,企业价值评估,股权评估,重组并购,公司估值,大数据",
				description: "心流网，心流慧估金融科技提供企业价值评估、股权评估、公司估值、重组并购大数据查询。心流自主研发曼殊估值专家系统，依托互联网、运用大数据和人工智能技术为企业股权估值。"
			}
		}
	},
	{
		path: '/report',
		name: 'report',
		component: report,
		meta: {
			title: '数据估值报告',
			content: {
				keywords: "心流,心流慧估,企业价值评估,股权评估,重组并购,公司估值,大数据",
				description: "心流网，心流慧估金融科技提供企业价值评估、股权评估、公司估值、重组并购大数据查询。心流自主研发曼殊估值专家系统，依托互联网、运用大数据和人工智能技术为企业股权估值。"
			}
		}
	},
	{
		path: '/dataValuationInfoFast',
		name: 'dataValuationInfoFast',
		component: dataValuationInfoFast,
		meta: {
			title: '基本信息',
		}
	},
	{
		path: '/dataValuationSoftFast',
		name: 'dataValuationSoftFast',
		component: dataValuationSoftFast,
		meta: {
			title: '软性指标',
		}
	},
	{
		path: '/dataValuationLiabilitiesFast',
		name: 'dataValuationLiabilitiesFast',
		component: dataValuationLiabilitiesFast,
		meta: {
			title: '资产负债表',
		}
	},
	{
		path: '/dataValuationProfitFast',
		name: 'dataValuationProfitFast',
		component: dataValuationProfitFast,
		meta: {
			title: '利润表',
		}
	},
	{
		path: '/dataResourceFast',
		name: 'dataResourceFast',
		component: dataResourceFast,
		meta: {
			title: '数据资源',
		}
	},
	{
		path: '/dataValuationPIAFast',
		name: 'dataValuationPIAFast',
		component: dataValuationPIAFast,
		meta: {
			title: '组合无形资产贡献程度',
		}
	},
	{
		path: '/dataValuationIAFast',
		name: 'dataValuationIAFast',
		component: dataValuationIAFast,
		meta: {
			title: '组合无形资产-数据资源贡献程度',
		}
	},
	{
		path: '/dataValuationPaymentFast',
		name: 'dataValuationPaymentFast',
		component: dataValuationPaymentFast,
		meta: {
			title: '估值报告',
		}
	},
	{
		path: '/dataValuationAuditFast',
		name: 'dataValuationAuditFast',
		component: dataValuationAuditFast,
		meta: {
			title: '审核状态',
		}
	},
	{
		path: '/dataValuationEInfoFast',
		name: 'dataValuationEInfoFast',
		component: dataValuationEInfoFast,
		meta: {
			title: '基本信息',
		}
	},
	{
		path: '/dataValuationESoftFast',
		name: 'dataValuationESoftFast',
		component: dataValuationESoftFast,
		meta: {
			title: '软性指标',
		}
	},
	{
		path: '/dataValuationEFinancialFast',
		name: 'dataValuationEFinancialFast',
		component: dataValuationEFinancialFast,
		meta: {
			title: '财务指标',
		}
	},
	{
		path: '/dataValuationEContributionFast',
		name: 'dataValuationEContributionFast',
		component: dataValuationEContributionFast,
		meta: {
			title: '贡献程度',
		}
	},
	{
		path: '/dataValuationReportFast',
		name: 'dataValuationReportFast',
		component: dataValuationReportFast,
		meta: {
			title: '报告(快速版)',
		}
	},
	// 专业版
	{
		path: '/dataInfoSpecialty',
		name: 'dataInfoSpecialty',
		component: dataInfoSpecialty,
		meta: {
			title: '基本信息',
		}
	},
	{
		path: '/dataSoftSpecialty',
		name: 'dataSoftSpecialty',
		component: dataSoftSpecialty,
		meta: {
			title: '软性指标',
		}
	},
	{
		path: '/dataLiabilitiesSpecialty',
		name: 'dataLiabilitiesSpecialty',
		component: dataLiabilitiesSpecialty,
		meta: {
			title: '资产负债表',
		}
	},
	{
		path: '/dataProfitSpecialty',
		name: 'dataProfitSpecialty',
		component: dataProfitSpecialty,
		meta: {
			title: '利润表',
		}
	},
	{
		path: '/dataAssetSpecialty',
		name: 'dataAssetSpecialty',
		component: dataAssetSpecialty,
		meta: {
			title: '数据资产',
		}
	},
	{
		path: '/dataIncomeForecast',
		name: 'dataIncomeForecast',
		component: dataIncomeForecast,
		meta: {
			title: '收入预测',
		}
	},
	{
		path: '/dataNetProfitForecast',
		name: 'dataNetProfitForecast',
		component: dataNetProfitForecast,
		meta: {
			title: '净利润预测',
		}
	},
	{
		path: '/dataAccountingPolicy',
		name: 'dataAccountingPolicy',
		component: dataAccountingPolicy,
		meta: {
			title: '会计政策统计表',
		}
	},
	{
		path: '/dataIASpecialty',
		name: 'dataIASpecialty',
		component: dataIASpecialty,
		meta: {
			title: '组合无形资产-数据资源贡献程度',
		}
	},
	{
		path: '/dataPIASpecialty',
		name: 'dataPIASpecialty',
		component: dataPIASpecialty,
		meta: {
			title: '组合无形资产贡献程度',
		}
	},
	{
		path: '/dataPaymentSpecialty',
		name: 'dataPaymentSpecialty',
		component: dataPaymentSpecialty,
		meta: {
			title: '估值报告',
		}
	},
	{
		path: '/dataAuditSpecialty',
		name: 'dataAuditSpecialty',
		component: dataAuditSpecialty,
		meta: {
			title: '审核状态',
		}
	},
	{
		path: '/memberbasicTitle',
		name: 'memberbasicTitle',
		component: memberbasicTitle,
		meta: {
			title: '基本信息',
		}
	},
	{
		path: '/viewSoftIndicators',
		name: 'viewSoftIndicators',
		component: viewSoftIndicators,
		meta: {
			title: '软性指标',
		}
	},
	{
		path: '/viewContributionLevel',
		name: 'viewContributionLevel',
		component: viewContributionLevel,
		meta: {
			title: '贡献程度',
		}
	}, {
		path: '/examineAssetLiability',
		name: 'examineAssetLiability',
		component: examineAssetLiability,
		meta: {
			title: '资产负债表',
		}
	}, {
		path: '/examineProfit',
		name: 'examineProfit',
		component: examineProfit,
		meta: {
			title: '利润表',
		}
	}, {
		path: '/examineDataAsset',
		name: 'examineDataAsset',
		component: examineDataAsset,
		meta: {
			title: '数据资产',
		}
	},
	{
		path: '/examineIncomeForecast',
		name: 'examineIncomeForecast',
		component: examineIncomeForecast,
		meta: {
			title: '收入预测',
		}
	}, {
		path: '/examineNetProfitForecast',
		name: 'examineNetProfitForecast',
		component: examineNetProfitForecast,
		meta: {
			title: '净利润预测',
		}
	}, {
		path: '/examineAccountingPolicy',
		name: 'examineAccountingPolicy',
		component: examineAccountingPolicy,
		meta: {
			title: '会计政策统计表',
		}
	}, {
		path: '/dataReportSpecialty',
		name: 'dataReportSpecialty',
		component: dataReportSpecialty,
		meta: {
			title: '报告（专业版）',
		}
	},
	{
		path: '/basicInformationEvaluation',
		name: 'basicInformationEvaluation',
		component: basicInformationEvaluation,
		meta: {
			title: '基本信息',
		}
	},
	{
		path: '/evaluationSoftIndicators',
		name: 'evaluationSoftIndicators',
		component: evaluationSoftIndicators,
		meta: {
			title: '软性指标',
		}
	},
	{
		path: '/evaluationValuationReport',
		name: 'evaluationValuationReport',
		component: evaluationValuationReport,
		meta: {
			title: '测评报告',
		}
	},
	{
		path: '/reportEvaluation',
		name: 'reportEvaluation',
		component: reportEvaluation,
		meta: {
			title: '数据价值测评报告',
		}
	},
	{
		path:'/evaluationCertificate',
		name: 'evaluationCertificate',
		component: evaluationCertificate,
		meta: {
			title: '数据价值测评证书',
		}
	}


]

const router = new VueRouter({
	routes
})

export default router