<template>
	<div class="EBG">
		<Header :type="type" />
		<div class="mainE" :style="{'height':'calc(100% - '+method.ratioEquality(1854,109,width-40)+'px)'}">
			<FillNav />

			<div class="fillinWidth">
				<p class="fillinTitleE">
					<span>软性指标</span>

					<softNav ref="softNav" :getSoftIndex="softIndexProblem" @softIndexSelected="updateSoftIndex" />
				</p>

				<div class="fillinMain">
					<img class="leftTop" src="../../../assets/img/dataEvaluation/filled/leftTop.png" />
					<img class="leftBottom" src="../../../assets/img/dataEvaluation/filled/leftBottom.png" />
					<img class="rightTop" src="../../../assets/img/dataEvaluation/filled/rightTop.png" />
					<img class="rightBottom" src="../../../assets/img/dataEvaluation/filled/rightBottom.png" />
					<img class="leftCenter" src="../../../assets/img/dataEvaluation/filled/leftCenter.png" />
					<div class="substance" id="xqBox">
						<div class="fillinContent">

							<div class="newCategory">
								{{softIndexProblem.contentName}}
								<div class="softFAQ" v-if="FAQ.length>0" @click="viewFAQ">
									<p>点击查看</p>
									<p>FAQ</p>
								</div>
							</div>

							<ul class="softFAQList" v-if="showFAQ">
								<img src="../../../assets/img/dataValutaion/close.png" alt="关闭" @click="closeFAQ" />
								<li v-for="item in FAQ">
									<p class="problemFAQ">{{item.question}}</p>
									<p class="answerFAQ">{{item.asked}}</p>
								</li>
							</ul>


							<ul>
								<li v-for="(item,index) in softIndexProblem.contentDetails">
									<div class="position-tit-container">
										<span>{{index+1}}</span>
										<div v-html="item.content"></div>
										<span class="desc-container" v-if="item.canExplain">
											<span></span>
											<div class="desc-main">
												<p v-html="item.explaination.replace(/\\n/g,'<br>')"></p>
												<span></span>
											</div>
										</span>
									</div>
									<div class="options-container">
										<ul>
											<li v-for="(answer, a) in item.options" @click="selectTopic(index,a)"
												:class="[answer.chooseFlag=='1'?'active':'']" ref="soft">
												<label :for="answer.id" :class="answer.chooseFlag=='1'?'yes':'no'">{{answer.content}}</label>
												<div class="desc-container" v-if="answer.canExplain!=false">
													<span></span>
													<div class="desc-main">
														<p v-html="answer.explaination.replace(/\\n/g,'<br>')"></p>
														<span></span>
													</div>
												</div>
											</li>
										</ul>

									</div>

								</li>
							</ul>
							<div class="saveInfo" @click="save">下一步</div>
						</div>

					</div>
				</div>

			</div>
		</div>


		<dialogBar v-model="myAlert" :type="popButonType" :title='markedWords' content="" @confirmJump="clickConfirmJump()"
			@closeMaskInfoJump="closeMaskInfoJump()" @cacheTrueMask="clickCacheTrueMaskJump()"
			@cacheMask="clickCacheMaskJump()"></dialogBar>

		<Modal v-model="alert" class-name="vertical-center-data videoAlert" :mask-closable="false">
			<div style="height: 315px;">
				<video :src="video" crossorigin="anonymous" class="example-video video-js vjs-big-play-centered vjs-fluid"
					controls preload="auto" style="height: 100%;"></video>

			</div>
			<p slot="footer"></p>
		</Modal>

	</div>
</template>

<script>
	import Header from '@/common/header/evaluate_header.vue'
	import FillNav from '../sidebar/fillNav.vue'
	import softNav from '../sidebar/softNav.vue'
	import common from '@/views/common'
	import dialogBar from '@/components/filled/popue.vue'
	import qs from 'qs'
	import axios from 'axios'

	import {
		mapState,
		mapMutations
	} from 'vuex'

	export default {
		mixins: [common],
		components: {
			Header,
			FillNav,
			softNav,
			dialogBar

		},
		data() {
			return {
				width: window.innerWidth,
				type: 14,
				isSave: false,
				softIndexProblem: {},
				selectedQuestion: [],
				softIndexProblemList: [],
				myAlert: false,
				popButonType: '',
				markedWords: '',
				softJArray: [],
				missingValue: 0, //哪个题没被选中
				alert: false,
				video: '',
				FAQ: [],
				showFAQ: false,
				projectName: this.domain.valuationProject

			}
		},
		watch: {
			softIndexProblem: {
				handler: function() {
					// console.log('getCompanyNameList改变了')
				},
				deep: true
			}
		},
		mounted() {
			this.getQuestion()

		},
		methods: {
			...mapMutations(['pageModify']),
			updateSoftIndex(selectedSoftIndex) {
				var this_ = this
				this.isSave = false
				this.pageModify('false');
				this.selectedQuestion = []

				this.$nextTick(function() {
					if (this.$refs.soft) {
						this.$refs.soft.forEach((item, i) => {
							if (parseInt(item.offsetHeight / 22) > 1) {
								item.style.borderRadius = '150px';
								// item.style.paddingTop = '10px';
							}
						})

						$(document).on('click', '.viewVideo', function() {
							this_.alert = true
							this_.video = this_.domain.testUrl + $(this).attr('data-url')
							console.log(this_.video)
						})

					}

					this.findFAQ()
				})


				if (JSON.parse(selectedSoftIndex).contentDetails[0].options[0].isCache === "0" && !sessionStorage
					.isHistoryCache) {
					this.myAlert = true;
					this.popButonType = 'cacheType';
					this.markedWords = '您上次填写过估值报告，是否载入缓存信息？';
					return false
				}

				this.softIndexProblem = JSON.parse(selectedSoftIndex) // 更新父组件的数据



				JSON.parse(selectedSoftIndex).contentDetails.map((el, i) => {
					el.options.map((item, j) => {
						if (item.chooseFlag == '1') {
							this.selectedQuestion.push(item.questionId)
						}
					})
				})


			},
			selectTopic(i, j) {
				this.isSave = false
				this.pageModify('true');
				this.softIndexProblem.contentDetails[i].options.map((el, b) => {
					if (j == b) {
						this.$set(this.softIndexProblem.contentDetails[i].options[j], 'chooseFlag', '1')
					} else {
						el.chooseFlag = 0
					}
				})

				$('.position-tit-container').removeClass('no-choose-red')

				this.selectedQuestion.push(this.softIndexProblem.contentDetails[i].options[j].questionId)
				this.$forceUpdate()
				// 缓存 ======================
				this.$nextTick(() => {
					this.getSaveData()
					//调用缓存共用方法
					if (!this.softIndexProblem.contentDetails[i].options[j].isCache) {
						this.method.caching("softJArray", this.softJArray, this.type, '', this)
					}
				})
				// 缓存 =======================

			},
			findFirstMissingValue(a, b) {
				var isContained = b.find(objB => !a.some(objA => objA == objB.id));
				// console.log(isContained)
				if (isContained) {
					return b.findIndex(obj => obj.id === isContained.id);
				} else {
					return isContained
				}
			},
			save() {
				let newList = Array.from(new Set(this.selectedQuestion))

				console.log(this.softIndexProblem.contentDetails, newList)

				const missingValue = this.findFirstMissingValue(newList, this.softIndexProblem.contentDetails);
				if (missingValue !== undefined) {
					// console.log(`数组 A 中第一个未包含在数组 B 中的值是: ${missingValue}`);
					this.missingValue = missingValue
					this.myAlert = true;
					this.popButonType = 'notEmpty-info';
					this.markedWords = '您有必填项未填写，请填写完成后再保存';
					return false

				} else {
					// console.log('数组 A 包含数组 B 中的所有值');
					if (!this.isSave) {
						this.isSave = true
						this.saveSoft()
					}

				}

			},
			getSaveData() {
				var softJArray = []
				this.softIndexProblem.contentDetails.map((el, i) => {
					el.options.map((els, j) => {
						if (els.chooseFlag == '1') {
							softJArray.push({
								questionId: els.questionId,
								optionId: els.id,
								contentType: this.softIndexProblem.contentType
							})
						}
					})
				})
				this.softJArray = JSON.stringify(softJArray)

			},
			saveSoft() {
				this.getSaveData()
				console.log(this.softJArray)
				this.$post(this.projectName + 'question/saveSoftIndex', qs.stringify({
						stage: '',
						reportType: this.type,
						reportId: this.$route.query.reportId ? this.$route.query.reportId : '',
						softJArray: this.softJArray,
						version: 2
					}))
					.then((res) => {
						if (this.softIndexProblem.contentType == this.softIndexProblemList[this.softIndexProblemList.length - 1]
							.contentType) {
							this.$router.push({
								path: '/evaluationValuationReport',
								query: {
									type: this.type,
									reportId: res.data.content
								}
							})
						} else {
							var params = {
								index: this.softIndexProblem.contentType,
								softIndexProblem: this.softIndexProblem.contentDetails,
								nextModule: true
							}
							this.$refs.softNav.updateChildData(params);
							this.$router.push({
								path: '/evaluationSoftIndicators',
								query: {
									type: '14',
									reportId: res.data.content
								}
							});
							var refxqBox = document.querySelector("#xqBox")
							refxqBox.scrollTop = 0
						}

					})
			},
			getQuestion() {
				this.$Spin.show(); //全局加载中
				var url = this.projectName + 'question/getQuestion';
				this.$post(url, qs.stringify({
						stage: sessionStorage.stage,
						reportType: 14,
						reportId: this.$route.query.reportId ? this.$route.query.reportId : '',
						version: sessionStorage.versions
					}))
					.then((res) => {
						this.$Spin.hide();
						this.softIndexProblem = res.data.content[0]
						this.softIndexProblemList = res.data.content

						this.selectedQuestion = []
						this.softIndexProblem.contentDetails.map((el, i) => {
							this.softIndexProblem.contentDetails[i]['id'] = el.options[0].questionId
							el.options.map((item, j) => {
								if (item.chooseFlag == '1') {
									this.selectedQuestion.push(item.questionId)
								}
							})
						})
					})
			},
			findFAQ() {
				this.$post(this.projectName + 'faq/findFAQ', qs.stringify({
						reportType: 14,
						tableType: 2, //导航栏（1：基本信息，2：软性指标， 3：财务指标）
						childTableType: this.softIndexProblem.contentType,
						key: '',
						pageNum: '-1',
						pageSize: '-1'
					}))
					.then(res => {
						this.FAQ = res.data.content
					})
			},
			viewFAQ() {
				this.showFAQ = true
			},
			closeFAQ() {
				this.showFAQ = false
			},

			// 关闭弹出层  点击确定回调
			clickConfirmJump() {
				console.log('跳转首页');

			},
			closeMaskInfoJump() {
				// console.log('点击软性指标')
				$('.position-tit-container').eq(this.missingValue).addClass('no-choose-red').parent('li').siblings().find(
					'.position-tit-container').removeClass('no-choose-red')
				var refxqBox = document.querySelector("#xqBox")
				refxqBox.scrollTop = $('.position-tit-container').eq(this.missingValue)[0].offsetTop
			},

			//是  载入缓存
			clickCacheTrueMaskJump() {
				sessionStorage.isHistoryCache = 1;
				this.getQuestion();
			},

			//否  载入缓存
			clickCacheMaskJump() {
				var this_ = this
				sessionStorage.isHistoryCache = 1;
				this.method.clearCaching(this.type, '', this, 'softJArray')
				setTimeout(function() {
					this_.getQuestion();
					this_.$refs.softNav.getQuestion(1);
				}, 100)
			},



		},

	}
</script>

<style scoped src="../../../assets/css/evaluateCommon.css"></style>
<style scoped lang="less">
	.softFAQList {
		top: 69px;
	}

	.position-tit-container>span {
		font-size: 16px;
	}

	.options-container label {
		font-size: 14px;
	}

	.desc-container .desc-main>p {
		font-size: 12px;
	}
</style>