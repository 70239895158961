<template>
	<div class="EBG">
		<Header :type="type" />
		<div class="mainE"
			:style="{'marginTop':method.ratioEquality(1854,90,width-100)+30+'px','minHeight':height-method.ratioEquality(1854,90,width-100)-30+'px'}">
			<fillinNav :stage="stage" :type="type" :versions="versions" :currentColumn='3' />

			<div class="fillinWidth">
				<div class="fillinTitleE">
					<p>
						<img src="../../../assets/img/dataValutaion/dataTitle.png" />
						<span>数据资源</span>
					</p>
				</div>

				<div class="fillinMain">
					<img class="leftTop" src="../../../assets/img/dataValutaion/leftTop.png" />
					<img class="leftBottom" src="../../../assets/img/dataValutaion/leftBottom.png" />
					<img class="rightTop" src="../../../assets/img/dataValutaion/rightTop.png" />
					<img class="rightBottom" src="../../../assets/img/dataValutaion/rightBottom.png" />
					<div class="substance">
						<div class="fillinContent">
							<DataResourceSpecialtyNav :stage="stage" :type="type" :versions="versions" :currentFinance='0' />

							<div class="newCategory">数据资源</div>


							<div class="dataAsset tabulationContent">
								<div class="selectiveType">
									<p>数据资源类型：<span class="reRed">*</span></p>
									<ul>
										<li v-for="(item,index) in types" :class="[typeIndex === index?'active':'']"
											@click="selectBusinessType(index)">
											<p>{{item.name}}</p>
											<p>{{item.describe}}</p>
										</li>
									</ul>
								</div>

								<Singleness ref="element" v-if="typeIndex==0" :stage="stage" :type="type" :versions="versions"
									:url="url" jumpAddress="dataIncomeForecast" />
								<Multiple ref="element" v-if="typeIndex==1" :stage="stage" :type="type" :versions="versions" :url="url"
									jumpAddress="dataIncomeForecast" />


							</div>


							<div class="saveBox">
								<router-link v-if="this.$route.query.reportId" tag="span" class="last buttonBorder"
									:to="{ path: '/dataAccountingPolicy', query: { reportId:this.$route.query.reportId } }">上一步</router-link>
								<router-link v-else tag="span" class="last buttonBorder" to="/dataAccountingPolicy">上一步</router-link>
								<span data-isClick="false" @click="save">下一步</span>
							</div>

						</div>
					</div>
				</div>
			</div>
		</div>


	</div>
</template>

<script>
	import Header from '@/common/header/evaluate_header.vue'
	import fillinNav from '../nav/fillDataNav.vue'
	import DataResourceSpecialtyNav from '../nav/dataResourceSpecialtyNav.vue'
	import Singleness from '@/components/financialStatement/dataAsset/singleness.vue'
	import Multiple from '@/components/financialStatement/dataAsset/multiple.vue'
	import common from '@/views/common'

	import qs from 'qs'
	import axios from 'axios';

	export default {
		mixins: [common],
		components: {
			Header,
			fillinNav,
			DataResourceSpecialtyNav,
			Singleness,
			Multiple

		},
		data() {
			return {
				width: window.innerWidth,
				height: window.innerHeight,
				url: this.domain.testUrl + this.domain.valuationProject,
				stage: '',
				type: '13',
				versions: '2',
				typeIndex: 0,
				types: [{ // 0：单一  1：多种
					name: '业务类型单一 ',
					describe: '数据资产无法根据业务类型做详细拆分'
				}, {
					name: '业务类型较多',
					describe: '对应不同业务类型，数据资产可做详细拆分及预测'
				}],

			}
		},
		mounted() {
			this.init()

		},
		methods: {
			selectBusinessType(i) {
				this.typeIndex = i
			},
			save() {
				this.$refs.element.nextStep()
			},
			init() {
				this.$post(this.url + 'dataResources/getDataResources', qs.stringify({
						reportId: this.$route.query.reportId ? this.$route.query.reportId : '',
					}))
					.then(res => {
						this.typeIndex = res.data.content.resourceType
						if (res.data.content.NetWorth == '') {
							this.showDialogBar(true, 'notClose', '请填写资产负债表中的无形资产')
							return false
						}

					})

			},
			//是  载入缓存
			clickCacheTrueMaskJump() {
				this.hideDialogBar()
			},

			//否  载入缓存
			clickCacheMaskJump() {
				this.hideDialogBar()
			},
			closeMaskInfoJump() {
				if (this.$route.query.reportId) {
					this.$router.push({
						name: 'dataLiabilitiesSpecialty',
						query: {
							reportId: this.$route.query.reportId
						}
					})
				} else {
					this.$router.push({
						name: 'dataLiabilitiesSpecialty',
					})
				}

				this.hideDialogBar()
			}


		}



	}
</script>

<style scoped lang="less">
	@import "~@/assets/css/dataValuationNew.less";

	.dataAsset {
		.selectiveType {
			display: flex;
			flex-wrap: wrap;
			margin-top: 20px;


			>p {
				font-size: 14px;
				color: #fff;
				padding-top: 11px;
				margin-bottom: 15px;
				margin-right: 20px;
			}

			ul {
				display: flex;

				li {
					height: 90px;
					background-size: 100% 100% !important;
					color: #fff;
					padding: 20px 0 20px 18px;
					margin-bottom: 15px;
					cursor: pointer;

					p:first-child {
						font-weight: bold;
						font-size: 16px;
					}

					p:last-child {
						font-size: 14px;
					}
				}

				li:nth-of-type(1) {
					width: 271px;
					background: url(../../../assets/img/dataResource/dataTypeShort.png) center center no-repeat;
					margin-right: 23px;
				}

				li:nth-of-type(2) {
					width: 354px;
					background: url(../../../assets/img/dataResource/dataType.png) center center no-repeat;
				}

				li:nth-of-type(1).active {
					background: url(../../../assets/img/dataResource/dataTypeSelectedShort.png) center center no-repeat;
					color: #E2B420;
				}

				li:nth-of-type(2).active {
					background: url(../../../assets/img/dataResource/dataTypeSelected.png) center center no-repeat;
					color: #E2B420;
				}
			}
		}

	}
</style>