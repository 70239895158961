<template>
	<div class="EBG">
		<Header :type="type" />
		<div class="newViewPage"
			:style="{'marginTop':method.ratioEquality(1854,90,width-100)+30+'px','minHeight':height-method.ratioEquality(1854,90,width-100)-30+'px'}">
			<examineTitle />
			<div class="examineContent">
				<div>
					<examineNav :type="type" category='数据资源' />
				</div>
				<div class="newViewInfo">
					<div class="fillinMain">
						<img class="leftTop" :src="require('@/assets/img/dataValutaion/leftTop.png')" />
						<img class="leftBottom" :src="require('@/assets/img/dataValutaion/leftBottom.png')" />
						<img class="rightTop" :src="require('@/assets/img/dataValutaion/rightTop.png')" />
						<img class="rightBottom" :src="require('@/assets/img/dataValutaion/rightBottom.png')" />
						<div class="fillinContent">
							<p class="examineTitle">数据资源</p>

							<div>
								<p class="unit">单位：元</p>

								<div class="yearLimit">
									<label for="netWorth" style="width: 268px;font-weight: bold;font-size: 16px;">
										无形资产账面净值
										<i
											style="font-size: 12px;">({{createTime?getLastDayOfPreviousMonthInChinese(createTime):'xxxx年xx月xx日'}})</i>
										<span class="reRed">*</span>
									</label>
									<div class="baseRight">
										<input type="number" id="netWorth" autocomplete="off">
										<span class="showInput">
											{{netWirth?this.method.formateNum(netWirth,2):''}}
										</span>
										<span class="measure">元</span>
									</div>
								</div>

								<div v-if="dataResources[0].resourceType==1" style="margin-top: 20px;">
									<div v-for="(bus,index) in singlenessBusiness" class="serviceLine">
										<div class="yearLimit businessName">
											<label for="businessName">业务名称<span class="reRed">*</span></label>
											<div class="baseRight">
												<input type="text" id="businessName" autocomplete="off" placeholder="请输入业务名称"
													v-model="bus.businessName">
											</div>
										</div>
										<div class="yearLimit" style="margin-bottom: 20px;">
											<label for="totalYear">预计未来经济寿命年限<span class="reRed">*</span></label>
											<div class="baseRight">
												<input type="number" id="totalYear" autocomplete="off" v-model="bus.serviceLife">
												<span class="measure">年</span>
											</div>
											<p v-text="'（2年≤年限≤10年）'"></p>
										</div>
										<div class="resourceList">
											<ul>
												<li v-for="(item,i) in JSON.parse(bus.dataResources)">
													<div class="detailedDisplay resourceFrame">
														<i></i>
														<div>
															<span>数据资源名称：</span>
															<span>{{item.resourceName}}</span>
														</div>

														<div>
															<p>
																<span>数据资源账面原值：</span>
																<span>{{item.originalValue?method.formateNum(item.originalValue,2)+'元':''}}</span>
															</p>
															<p>
																<span>数据资源账面净值：
																	<i
																		style="position: absolute;left: 0;top: 18px;font-size: 12px;">（{{createTime?getLastDayOfPreviousMonthInChinese(createTime):'xxxx年xx月xx日'}}）</i>
																</span>
																<span>{{method.formateNum(item.netValue,2)}}元</span>
															</p>
														</div>
														<div>
															<span>数据资源介绍：</span>
															<span>{{item.resDescribe}}</span>
														</div>
														<i></i>
													</div>
												</li>
											</ul>
										</div>
									</div>
									
									<div class="totalNetWorth">
										<span>数据资源账面净值合计</span>
										<span>{{method.formateNum(JSON.parse(productSum[0].dataResources)[2].value,2)}}</span>
									</div>
									
									
								</div>

								<div v-else>
									<div v-for="(bus,index) in singlenessBusiness">
										<div class="yearLimit" style="margin-bottom: 20px;">
											<label for="totalYear">预计未来经济寿命年限<span class="reRed">*</span></label>
											<div class="baseRight">
												<input type="number" id="totalYear" autocomplete="off" v-model="bus.serviceLife">
												<span class="measure">年</span>
											</div>
											<p v-text="'（2年≤年限≤10年）'"></p>
										</div>
										<div class="resourceList">
											<ul>
												<li v-for="(item,i) in JSON.parse(bus.dataResources)">
													<div class="detailedDisplay resourceFrame">
														<i></i>
														<div>
															<span>数据资源名称：</span>
															<span>{{item.resourceName}}</span>
														</div>

														<div>
															<p>
																<span>数据资源账面原值：</span>
																<span>{{item.originalValue?method.formateNum(item.originalValue,2)+'元':''}}</span>
															</p>
															<p>
																<span>数据资源账面净值：
																	<i
																		style="position: absolute;left: 0;top: 18px;font-size: 12px;">（{{createTime?getLastDayOfPreviousMonthInChinese(createTime):'xxxx年xx月xx日'}}）</i>
																</span>
																<span>{{method.formateNum(item.netValue,2)}}元</span>
															</p>
														</div>
														<div>
															<span>数据资源介绍：</span>
															<span>{{item.resDescribe}}</span>
														</div>
														<i></i>
													</div>
												</li>
												<li class="totalNetWorth">
													<span>数据资源账面净值合计</span>
													<span>{{method.formateNum(JSON.parse(productSum[0].dataResources)[2].value,2)}}</span>
												</li>
											</ul>
										</div>
									</div>
								</div>

							</div>



						</div>
					</div>
				</div>

			</div>
		</div>
	</div>
</template>

<script>
	import Header from '@/common/header/evaluate_header.vue'
	import examineTitle from '@/common/fillin/examineTitle.vue'
	import common from '@/views/common'
	import examineNav from '../../nav/examineNav.vue'
	import qs from 'qs'
	import axios from 'axios'

	export default {
		mixins: [common],
		components: {
			Header,
			examineTitle,
			examineNav
		},
		data() {
			return {
				width: window.innerWidth,
				height: window.innerHeight,
				type: '13',
				dataResources: [{
					resourceType: ''
				}],
				singlenessBusiness: [],
				productSum: [],
				createTime: '',
				netWirth: ''


			}
		},
		mounted() {
			this.init()
		},
		methods: {
			init() {
				var this_ = this
				this.$post(this.domain.valuationProject + 'auditInfoShow/getDataFinancialFutureValue', qs.stringify({
						reportId: this.$route.query.reportId ? this.$route.query.reportId : ''
					}))
					.then((res) => {
						this.$Spin.hide();

						this.createTime = res.data.content.createTime
						this.netWirth = res.data.content.netWirth
						this.dataResources = res.data.content.dataResources


						res.data.content.dataResources.map(el => {
							if (el.businessName == 'productSum') {
								this.productSum.push(el)
							} else {
								this.singlenessBusiness.push(el)
							}
						})

						console.log(this.singlenessBusiness)



					})
			}

		}
	}
</script>

<style scoped lang="less">
	@import "~@/assets/css/dataValuationNew.less";
	input {
		pointer-events: none;
	}

	.serviceLine {
		width: 100%;
		border-top: 3px dotted #fff;
		padding-top: 30px;
		margin-bottom: 40px;
		position: relative;
	}

	.line {
		border-bottom: 3px dotted #fff;
	}

	.addBusinessType {
		width: 480px;
		height: 36px;
		line-height: 36px;
		background: #29FFFC;
		border-radius: 6px;
		font-size: 14px;
		color: #1237C6;
		text-align: center;
		margin: 25px auto;
		cursor: pointer;
	}

	.yearLimit {
		display: flex;
		align-items: center;
		flex-wrap: wrap;
		padding: 10px 0;

		>p {
			font-size: 14px;
			color: #fff;
		}

		label {
			width: 268px;
			font-weight: bold;
			font-size: 16px;
			color: #fff;
		}

		input,
		.baseRight,
		.showInput {
			width: 268px;
		}

		input {
			width: 82%;
			height: 30px;
			background: #1237C6;
			border: 1px solid #29FFFC;
			font-size: 14px;
			color: #fff;
			padding: 0 26px 0 10px;
		}

		input:focus {
			border-color: #fff;
		}

		.showInput {
			width: 80%;
			height: 27px;
			line-height: 30px;
			background: #1237C6;
			padding: 0 6px;
			color: #fff;
			font-size: 14px;
			position: absolute;
			top: 1px;
			left: 1px;
			overflow: hidden;
		}

		.measure {
			width: 25px;
			height: 30px;
			line-height: 30px;
			text-align: center;
			position: absolute;
			top: 0;
			right: 11px;
			color: #fff;
			font-size: 14px;
		}

		.baseRight {
			height: 30px;
			position: relative;
		}
	}
</style>