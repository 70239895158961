<template>
	<div class="profitSpecialty">
		<div class="newCategory">利润表</div>
		<div class="inputTabDesc">
			<p class="table-desc-tit">
				<img :src="require('@/assets/img/finance/hint.png')" alt="填表说明" />
				<span>填表说明</span>
			</p>
			<ul>
				<li>
					<span><i>1、</i><i>利润表请务必按实际情况填报完整，各明细科目合计应与该一级科目填报数相等。</i></span>
				</li>
				<li>
					<span><i>2、</i><i>灰色为自动算出项，不可填写。</i></span>
				</li>
				<li>
					<span><i>3、</i><i>选填项中，如果该科目在对应的会计年度存在发生额，请如实填报。</i></span>
				</li>
			</ul>
		</div>

		<div class="specialtyFinance">
			<p class="unit">单位：元</p>
			<div>
				<ul class="yeaAccount">
					<i></i>
					<li>
						<span>项目</span>
						<span v-for="item in yearList">{{item}}度</span>
					</li>
					<i></i>
				</ul>

				<div class="tabulation">
					<ul class="professionalForm">
						<li>
							<span class="trOne">一、营业收入</span>
							<span v-for="(item,i) in yearList">
								<input type="number" v-model="profit.income[i]" @change="change"
									@blur="((ev)=>{blur(ev,profit,'income',i)})" @focus="focus($event)"
									@wheel="method.disableWheel($event)">
								<span class="showInput" @click="inputFocus($event)">
									{{judgingCondition('income',i)?method.formateNum(profit.income[i],2):'-'}}
								</span>
							</span>
						</li>
						<li>
							<span class="profitTrTwo">减：营业成本</span>
							<span v-for="(item,i) in yearList">
								<input type="number" v-model="profit.cost[i]" @change="change"
									@blur="((ev)=>{blur(ev,profit,'cost',i)})" @focus="focus($event)"
									@wheel="method.disableWheel($event)">
								<span class="showInput" @click="inputFocus($event)">
									{{judgingCondition('cost',i)?method.formateNum(profit.cost[i],2):'-'}}
								</span>
							</span>
						</li>
						<li>
							<span class="profitTrThree">税金及附加</span>
							<span v-for="(item,i) in yearList">
								<input type="number" v-model="profit.taxAdditional[i]" @change="change"
									@blur="((ev)=>{blur(ev,profit,'taxAdditional',i)})" @focus="focus($event)"
									@wheel="method.disableWheel($event)">
								<span class="showInput" @click="inputFocus($event)">
									{{judgingCondition('taxAdditional',i)?method.formateNum(profit.taxAdditional[i],2):'-'}}
								</span>
							</span>
						</li>
						<li>
							<span class="profitTrThree">销售费用</span>
							<span v-for="(item,i) in yearList">
								<input type="number" v-model="profit.saleCost[i]" @change="change"
									@blur="((ev)=>{blur(ev,profit,'saleCost',i)})" @focus="focus($event)"
									@wheel="method.disableWheel($event)">
								<span class="showInput" @click="inputFocus($event)">
									{{judgingCondition('saleCost',i)?method.formateNum(profit.saleCost[i],2):'-'}}
								</span>
							</span>
						</li>
						<li>
							<span class="profitTrThree">管理费用</span>
							<span v-for="(item,i) in yearList">
								<input type="number" v-model="profit.manageCost[i]" @change="change"
									@blur="((ev)=>{blur(ev,profit,'manageCost',i)})" @focus="focus($event)"
									@wheel="method.disableWheel($event)">
								<span class="showInput" @click="inputFocus($event)">
									{{judgingCondition('manageCost',i)?method.formateNum(profit.manageCost[i],2):'-'}}
								</span>
							</span>
						</li>
						<li>
							<span class="profitTrThree">研发费用</span>
							<span v-for="(item,i) in yearList">
								<input type="number" v-model="profit.researchCost[i]" @change="change"
									@blur="((ev)=>{blur(ev,profit,'researchCost',i)})" @focus="focus($event)"
									@wheel="method.disableWheel($event)">
								<span class="showInput" @click="inputFocus($event)">
									{{judgingCondition('researchCost',i)?method.formateNum(profit.researchCost[i],2):'-'}}
								</span>
							</span>
						</li>
						<li>
							<span class="profitTrThree">财务费用</span>
							<span v-for="(item,i) in yearList">
								<input type="number" v-model="profit.financeCost[i]" @change="change"
									@blur="((ev)=>{blur(ev,profit,'financeCost',i)})" @focus="focus($event)"
									@wheel="method.disableWheel($event)">
								<span class="showInput" @click="inputFocus($event)">
									{{judgingCondition('financeCost',i)?method.formateNum(profit.financeCost[i],2):'-'}}
								</span>
							</span>
						</li>
						<li>
							<span class="profitTrFour">其中：利息支出</span>
							<span v-for="(item,i) in yearList">
								<input type="number" v-model="profit.interestCost[i]" @change="change"
									@blur="((ev)=>{blur(ev,profit,'interestCost',i)})" @focus="focus($event)"
									@wheel="method.disableWheel($event)">
								<span class="showInput" @click="inputFocus($event)">
									{{judgingCondition('interestCost',i)?method.formateNum(profit.interestCost[i],2):'-'}}
								</span>
							</span>
						</li>
						<li>
							<span class="profitTrFive">利息收入</span>
							<span v-for="(item,i) in yearList">
								<input type="number" v-model="profit.interestIncome[i]" @change="change"
									@blur="((ev)=>{blur(ev,profit,'interestIncome',i)})" @focus="focus($event)"
									@wheel="method.disableWheel($event)">
								<span class="showInput" @click="inputFocus($event)">
									{{judgingCondition('interestIncome',i)?method.formateNum(profit.interestIncome[i],2):'-'}}
								</span>
							</span>
						</li>
						<li>
							<span class="profitTrTwo">加：其他收益</span>
							<span v-for="(item,i) in yearList">
								<input type="number" v-model="profit.otherBenefits[i]" @change="change"
									@blur="((ev)=>{blur(ev,profit,'otherBenefits',i)})" @focus="focus($event)"
									@wheel="method.disableWheel($event)">
								<span class="showInput" @click="inputFocus($event)">
									{{judgingCondition('otherBenefits',i)?method.formateNum(profit.otherBenefits[i],2):'-'}}
								</span>
							</span>
						</li>
						<li>
							<span class="profitTrThree">投资收益(损失以"-"号填列)</span>
							<span v-for="(item,i) in yearList">
								<input type="number" v-model="profit.investmentIncome[i]" @change="change"
									@blur="((ev)=>{blur(ev,profit,'investmentIncome',i)})" @focus="focus($event)"
									@wheel="method.disableWheel($event)">
								<span class="showInput" @click="inputFocus($event)">
									{{judgingCondition('investmentIncome',i)?method.formateNum(profit.investmentIncome[i],2):'-'}}
								</span>
							</span>
						</li>
						<li>
							<span class="profitTrFour">其中：对联营企业和合营企业的投资收益</span>
							<span v-for="(item,i) in yearList">
								<input type="number" v-model="profit.enterpriseIncome[i]" @change="change"
									@blur="((ev)=>{blur(ev,profit,'enterpriseIncome',i)})" @focus="focus($event)"
									@wheel="method.disableWheel($event)">
								<span class="showInput" @click="inputFocus($event)">
									{{judgingCondition('enterpriseIncome',i)?method.formateNum(profit.enterpriseIncome[i],2):'-'}}
								</span>
							</span>
						</li>
						<li>
							<span class="profitTrFive">以摊余成本计量的金融资产终止确认收益（损失以"-"号填列）</span>
							<span v-for="(item,i) in yearList">
								<input type="number" v-model="profit.financialAssetsBenefits[i]" @change="change"
									@blur="((ev)=>{blur(ev,profit,'financialAssetsBenefits',i)})" @focus="focus($event)"
									@wheel="method.disableWheel($event)">
								<span class="showInput" @click="inputFocus($event)">
									{{judgingCondition('financialAssetsBenefits',i)?method.formateNum(profit.financialAssetsBenefits[i],2):'-'}}
								</span>
							</span>
						</li>
						<li>
							<span class="profitTrThree">净敞口套期收益（损失以"-"号填列）</span>
							<span v-for="(item,i) in yearList">
								<input type="number" v-model="profit.netExposureHedgeIncome[i]" @change="change"
									@blur="((ev)=>{blur(ev,profit,'netExposureHedgeIncome',i)})" @focus="focus($event)"
									@wheel="method.disableWheel($event)">
								<span class="showInput" @click="inputFocus($event)">
									{{judgingCondition('netExposureHedgeIncome',i)?method.formateNum(profit.netExposureHedgeIncome[i],2):'-'}}
								</span>
							</span>
						</li>
						<li>
							<span class="profitTrThree">公允价值变动收益（损失以"-"号填列）</span>
							<span v-for="(item,i) in yearList">
								<input type="number" v-model="profit.fairValueIncome[i]" @change="change"
									@blur="((ev)=>{blur(ev,profit,'fairValueIncome',i)})" @focus="focus($event)"
									@wheel="method.disableWheel($event)">
								<span class="showInput" @click="inputFocus($event)">
									{{judgingCondition('fairValueIncome',i)?method.formateNum(profit.fairValueIncome[i],2):'-'}}
								</span>
							</span>
						</li>
						<li>
							<span class="profitTrThree">信用减值损失（损失以"-"号填列）</span>
							<span v-for="(item,i) in yearList">
								<input type="number" v-model="profit.creditImpairmentLoss[i]" @change="change"
									@blur="((ev)=>{blur(ev,profit,'creditImpairmentLoss',i)})" @focus="focus($event)"
									@wheel="method.disableWheel($event)">
								<span class="showInput" @click="inputFocus($event)">
									{{judgingCondition('creditImpairmentLoss',i)?method.formateNum(profit.creditImpairmentLoss[i],2):'-'}}
								</span>
							</span>
						</li>
						<li>
							<span class="profitTrThree">资产减值损失（损失以"-"号填列）</span>
							<span v-for="(item,i) in yearList">
								<input type="number" v-model="profit.assetLoss[i]" @change="change"
									@blur="((ev)=>{blur(ev,profit,'assetLoss',i)})" @focus="focus($event)"
									@wheel="method.disableWheel($event)">
								<span class="showInput" @click="inputFocus($event)">
									{{judgingCondition('assetLoss',i)?method.formateNum(profit.assetLoss[i],2):'-'}}
								</span>
							</span>
						</li>
						<li>
							<span class="profitTrThree">资产处置收益（损失以"-"号填列）</span>
							<span v-for="(item,i) in yearList">
								<input type="number" v-model="profit.assetDisposalIncome[i]" @change="change"
									@blur="((ev)=>{blur(ev,profit,'assetDisposalIncome',i)})" @focus="focus($event)"
									@wheel="method.disableWheel($event)">
								<span class="showInput" @click="inputFocus($event)">
									{{judgingCondition('assetDisposalIncome',i)?method.formateNum(profit.assetDisposalIncome[i],2):'-'}}
								</span>
							</span>
						</li>
						<li>
							<span class="trOne">二、营业利润（亏损以"-"号填列）</span>
							<span v-for="(item,i) in yearList">
								<input type="number" v-model="profit.businessProfit[i]" disabled>
								<span class="showInput">
									{{judgingCondition('businessProfit',i)?method.formateNum(profit.businessProfit[i],2):'-'}}
								</span>
							</span>
						</li>
						<li>
							<span class="profitTrTwo">加：营业外收入</span>
							<span v-for="(item,i) in yearList">
								<input type="number" v-model="profit.outsideIncome[i]" @change="change"
									@blur="((ev)=>{blur(ev,profit,'outsideIncome',i)})" @focus="focus($event)"
									@wheel="method.disableWheel($event)">
								<span class="showInput" @click="inputFocus($event)">
									{{judgingCondition('outsideIncome',i)?method.formateNum(profit.outsideIncome[i],2):'-'}}
								</span>
							</span>
						</li>
						<li>
							<span class="profitTrTwo">减：营业外支出</span>
							<span v-for="(item,i) in yearList">
								<input type="number" v-model="profit.outsideExpenditure[i]" @change="change"
									@blur="((ev)=>{blur(ev,profit,'outsideExpenditure',i)})" @focus="focus($event)"
									@wheel="method.disableWheel($event)">
								<span class="showInput" @click="inputFocus($event)">
									{{judgingCondition('outsideExpenditure',i)?method.formateNum(profit.outsideExpenditure[i],2):'-'}}
								</span>
							</span>
						</li>
						<li>
							<span class="trOne">三、利润总额（亏损总额以"-"号填列）</span>
							<span v-for="(item,i) in yearList">
								<input type="number" v-model="profit.totalProfit[i]" disabled>
								<span class="showInput">
									{{judgingCondition('totalProfit',i)?method.formateNum(profit.totalProfit[i],2):'-'}}
								</span>
							</span>
						</li>
						<li>
							<span class="profitTrTwo">减：所得税费用</span>
							<span v-for="(item,i) in yearList">
								<input type="number" v-model="profit.incomeTax[i]" @change="change"
									@blur="((ev)=>{blur(ev,profit,'incomeTax',i)})" @focus="focus($event)"
									@wheel="method.disableWheel($event)">
								<span class="showInput" @click="inputFocus($event)">
									{{judgingCondition('incomeTax',i)?method.formateNum(profit.incomeTax[i],2):'-'}}
								</span>
							</span>
						</li>
						<li>
							<span class="trOne">四、净利润（净亏损以"-"号填列）</span>
							<span v-for="(item,i) in yearList">
								<input type="number" v-model="profit.netProfit[i]" disabled>
								<span class="showInput">
									{{judgingCondition('netProfit',i)?method.formateNum(profit.netProfit[i],2):'-'}}
								</span>
							</span>
						</li>
						<li>
							<span class="profitTrTwo">(一) 按经营持续性分类</span>
							<span v-for="(item,i) in yearList"></span>
						</li>
						<li>
							<span class="profitTrThree">1.持续经营净利润（净亏损以"-"号填列）</span>
							<span v-for="(item,i) in yearList">
								<input type="number" v-model="profit.goingConcernNetProfit[i]" @change="change"
									@blur="((ev)=>{blur(ev,profit,'goingConcernNetProfit',i)})" @focus="focus($event)"
									@wheel="method.disableWheel($event)">
								<span class="showInput" @click="inputFocus($event)">
									{{judgingCondition('goingConcernNetProfit',i)?method.formateNum(profit.goingConcernNetProfit[i],2):'-'}}
								</span>
							</span>
						</li>
						<li>
							<span class="profitTrThree">2.终止经营净利润（净亏损以"-"号填列）</span>
							<span v-for="(item,i) in yearList">
								<input type="number" v-model="profit.discontinuedOperationsNetProfit[i]" @change="change"
									@blur="((ev)=>{blur(ev,profit,'discontinuedOperationsNetProfit',i)})" @focus="focus($event)"
									@wheel="method.disableWheel($event)">
								<span class="showInput" @click="inputFocus($event)">
									{{judgingCondition('discontinuedOperationsNetProfit',i)?method.formateNum(profit.discontinuedOperationsNetProfit[i],2):'-'}}
								</span>
							</span>
						</li>
						<li>
							<span class="profitTrTwo">(二) 按所有权归属分类</span>
							<span v-for="(item,i) in yearList"></span>
						</li>
						<li>
							<span class="profitTrThree">1.归属于母公司股东的净利润（净亏损以"-"号填列）</span>
							<span v-for="(item,i) in yearList">
								<input type="number" v-model="profit.parentCompanyNetProfit[i]" @change="change"
									@blur="((ev)=>{blur(ev,profit,'parentCompanyNetProfit',i)})" @focus="focus($event)"
									@wheel="method.disableWheel($event)">
								<span class="showInput" @click="inputFocus($event)">
									{{judgingCondition('parentCompanyNetProfit',i)?method.formateNum(profit.parentCompanyNetProfit[i],2):'-'}}
								</span>
							</span>
						</li>
						<li>
							<span class="profitTrThree">2.少数股东损益（净亏损以"-"号填列）</span>
							<span v-for="(item,i) in yearList">
								<input type="number" v-model="profit.minorityStockLegalRight[i]" @change="change"
									@blur="((ev)=>{blur(ev,profit,'minorityStockLegalRight',i)})" @focus="focus($event)"
									@wheel="method.disableWheel($event)">
								<span class="showInput" @click="inputFocus($event)">
									{{judgingCondition('minorityStockLegalRight',i)?method.formateNum(profit.minorityStockLegalRight[i],2):'-'}}
								</span>
							</span>
						</li>
						<li>
							<span class="trOne">五、其他综合收益的税后净额</span>
							<span v-for="(item,i) in yearList">
								<input type="number" v-model="profit.ociNetTax[i]" disabled>
								<span class="showInput">
									{{judgingCondition('ociNetTax',i)?method.formateNum(profit.ociNetTax[i],2):'-'}}
								</span>
							</span>
						</li>
						<li>
							<span class="profitTrTwo">(一)归属于母公司所有者的其他综合收益的税后净额 </span>
							<span v-for="(item,i) in yearList">
								<input type="number" v-model="profit.parentCompanyOciNetTax[i]" disabled>
								<span class="showInput">
									{{judgingCondition('parentCompanyOciNetTax',i)?method.formateNum(profit.parentCompanyOciNetTax[i],2):'-'}}
								</span>
							</span>
						</li>
						<li>
							<span class="profitTrThree">1.不能重分类进损益的其他综合收益</span>
							<span v-for="(item,i) in yearList">
								<input type="number" v-model="profit.notReclassifiedOci[i]" disabled>
								<span class="showInput">
									{{judgingCondition('notReclassifiedOci',i)?method.formateNum(profit.notReclassifiedOci[i],2):'-'}}
								</span>
							</span>
						</li>
						<li>
							<span class="profitTrSix">(1)重新计量设定受益计划变动额</span>
							<span v-for="(item,i) in yearList">
								<input type="number" v-model="profit.remeasureBenefitAmount[i]" @change="change"
									@blur="((ev)=>{blur(ev,profit,'remeasureBenefitAmount',i)})" @focus="focus($event)"
									@wheel="method.disableWheel($event)">
								<span class="showInput" @click="inputFocus($event)">
									{{judgingCondition('remeasureBenefitAmount',i)?method.formateNum(profit.remeasureBenefitAmount[i],2):'-'}}
								</span>
							</span>
						</li>
						<li>
							<span class="profitTrSix">(2)权益法下不能转损益的其他综合收益</span>
							<span v-for="(item,i) in yearList">
								<input type="number" v-model="profit.notTransferOci[i]" @change="change"
									@blur="((ev)=>{blur(ev,profit,'notTransferOci',i)})" @focus="focus($event)"
									@wheel="method.disableWheel($event)">
								<span class="showInput" @click="inputFocus($event)">
									{{judgingCondition('notTransferOci',i)?method.formateNum(profit.notTransferOci[i],2):'-'}}
								</span>
							</span>
						</li>
						<li>
							<span class="profitTrSix">(3)其他权益工具投资公允价值变动</span>
							<span v-for="(item,i) in yearList">
								<input type="number" v-model="profit.otherEquityFairValue[i]" @change="change"
									@blur="((ev)=>{blur(ev,profit,'otherEquityFairValue',i)})" @focus="focus($event)"
									@wheel="method.disableWheel($event)">
								<span class="showInput" @click="inputFocus($event)">
									{{judgingCondition('otherEquityFairValue',i)?method.formateNum(profit.otherEquityFairValue[i],2):'-'}}
								</span>
							</span>
						</li>
						<li>
							<span class="profitTrSix">(4)企业自身信用风险公允价值变动</span>
							<span v-for="(item,i) in yearList">
								<input type="number" v-model="profit.enterpriseCreditRiskFairValue[i]" @change="change"
									@blur="((ev)=>{blur(ev,profit,'enterpriseCreditRiskFairValue',i)})" @focus="focus($event)"
									@wheel="method.disableWheel($event)">
								<span class="showInput" @click="inputFocus($event)">
									{{judgingCondition('enterpriseCreditRiskFairValue',i)?method.formateNum(profit.enterpriseCreditRiskFairValue[i],2):'-'}}
								</span>
							</span>
						</li>
						<li>
							<span class="profitTrThree">2.将重分类进损益的其他综合收益</span>
							<span v-for="(item,i) in yearList">
								<input type="number" v-model="profit.reclassifiedOci[i]" disabled>
								<span class="showInput">
									{{judgingCondition('reclassifiedOci',i)?method.formateNum(profit.reclassifiedOci[i],2):'-'}}
								</span>
							</span>
						</li>
						<li>
							<span class="profitTrSix">(1)权益法下可转损益的其他综合收益</span>
							<span v-for="(item,i) in yearList">
								<input type="number" v-model="profit.transferOci[i]" @change="change"
									@blur="((ev)=>{blur(ev,profit,'transferOci',i)})" @focus="focus($event)"
									@wheel="method.disableWheel($event)">
								<span class="showInput" @click="inputFocus($event)">
									{{judgingCondition('transferOci',i)?method.formateNum(profit.transferOci[i],2):'-'}}
								</span>
							</span>
						</li>
						<li>
							<span class="profitTrSix">(2)其他债权投资公允价值变动</span>
							<span v-for="(item,i) in yearList">
								<input type="number" v-model="profit.otherClaimsFairValue[i]" @change="change"
									@blur="((ev)=>{blur(ev,profit,'otherClaimsFairValue',i)})" @focus="focus($event)"
									@wheel="method.disableWheel($event)">
								<span class="showInput" @click="inputFocus($event)">
									{{judgingCondition('otherClaimsFairValue',i)?method.formateNum(profit.otherClaimsFairValue[i],2):'-'}}
								</span>
							</span>
						</li>
						<li>
							<span class="profitTrSix">(3)金融资产重分类计入其他综合收益的金额 </span>
							<span v-for="(item,i) in yearList">
								<input type="number" v-model="profit.financialAssetsOciAmount[i]" @change="change"
									@blur="((ev)=>{blur(ev,profit,'financialAssetsOciAmount',i)})" @focus="focus($event)"
									@wheel="method.disableWheel($event)">
								<span class="showInput" @click="inputFocus($event)">
									{{judgingCondition('financialAssetsOciAmount',i)?method.formateNum(profit.financialAssetsOciAmount[i],2):'-'}}
								</span>
							</span>
						</li>
						<li>
							<span class="profitTrSix">(4)其他债权投资信用减值准备</span>
							<span v-for="(item,i) in yearList">
								<input type="number" v-model="profit.otherClaimsCreditImpairment[i]" @change="change"
									@blur="((ev)=>{blur(ev,profit,'otherClaimsCreditImpairment',i)})" @focus="focus($event)"
									@wheel="method.disableWheel($event)">
								<span class="showInput" @click="inputFocus($event)">
									{{judgingCondition('otherClaimsCreditImpairment',i)?method.formateNum(profit.otherClaimsCreditImpairment[i],2):'-'}}
								</span>
							</span>
						</li>
						<li>
							<span class="profitTrSix">(5)现金流量套期储备</span>
							<span v-for="(item,i) in yearList">
								<input type="number" v-model="profit.cashFlowHedgingReserve[i]" @change="change"
									@blur="((ev)=>{blur(ev,profit,'cashFlowHedgingReserve',i)})" @focus="focus($event)"
									@wheel="method.disableWheel($event)">
								<span class="showInput" @click="inputFocus($event)">
									{{judgingCondition('cashFlowHedgingReserve',i)?method.formateNum(profit.cashFlowHedgingReserve[i],2):'-'}}
								</span>
							</span>
						</li>
						<li>
							<span class="profitTrSix">(6)外币财务报表折算差额</span>
							<span v-for="(item,i) in yearList">
								<input type="number" v-model="profit.foreignCurrencyBalance[i]" @change="change"
									@blur="((ev)=>{blur(ev,profit,'foreignCurrencyBalance',i)})" @focus="focus($event)"
									@wheel="method.disableWheel($event)">
								<span class="showInput" @click="inputFocus($event)">
									{{judgingCondition('foreignCurrencyBalance',i)?method.formateNum(profit.foreignCurrencyBalance[i],2):'-'}}
								</span>
							</span>
						</li>
						<li>
							<span class="profitTrTwo">(二)归属于少数股东的其他综合收益的税后净额</span>
							<span v-for="(item,i) in yearList">
								<input type="number" v-model="profit.minorityStockOciNetTax[i]" @change="change"
									@blur="((ev)=>{blur(ev,profit,'minorityStockOciNetTax',i)})" @focus="focus($event)"
									@wheel="method.disableWheel($event)">
								<span class="showInput" @click="inputFocus($event)">
									{{judgingCondition('minorityStockOciNetTax',i)?method.formateNum(profit.minorityStockOciNetTax[i],2):'-'}}
								</span>
							</span>
						</li>
						<li>
							<span class="trOne">六、综合收益总额</span>
							<span v-for="(item,i) in yearList">
								<input type="number" v-model="profit.totalComprehensiveIncome[i]" disabled>
								<span class="showInput">
									{{judgingCondition('totalComprehensiveIncome',i)?method.formateNum(profit.totalComprehensiveIncome[i],2):'-'}}
								</span>
							</span>
						</li>
						<li>
							<span class="trTwo">(一)归属于母公司所有者的综合收益总额</span>
							<span v-for="(item,i) in yearList">
								<input type="number" v-model="profit.parentCompanyTci[i]" disabled>
								<span class="showInput">
									{{judgingCondition('parentCompanyTci',i)?method.formateNum(profit.parentCompanyTci[i],2):'-'}}
								</span>
							</span>
						</li>
						<li>
							<span class="trTwo">(二)归属于少数股东的综合收益总额</span>
							<span v-for="(item,i) in yearList">
								<input type="number" v-model="profit.minorityStockTci[i]" disabled>
								<span class="showInput">
									{{judgingCondition('minorityStockTci',i)?method.formateNum(profit.minorityStockTci[i],2):'-'}}
								</span>
							</span>
						</li>
						<li>
							<span class="trOne">七、每股收益</span>
							<span v-for="(item,i) in yearList"></span>
						</li>
						<li>
							<span class="profitTrTwo">(一)基本每股收益</span>
							<span v-for="(item,i) in yearList">
								<input type="number" v-model="profit.basicEps[i]" @change="change"
									@blur="((ev)=>{blur(ev,profit,'basicEps',i)})" @focus="focus($event)"
									@wheel="method.disableWheel($event)">
								<span class="showInput" @click="inputFocus($event)">
									{{judgingCondition('basicEps',i)?method.formateNum(profit.basicEps[i],2):'-'}}
								</span>
							</span>
						</li>
						<li>
							<span class="profitTrTwo">(二)稀释每股收益</span>
							<span v-for="(item,i) in yearList">
								<input type="number" v-model="profit.dilutedEps[i]" @change="change"
									@blur="((ev)=>{blur(ev,profit,'dilutedEps',i)})" @focus="focus($event)"
									@wheel="method.disableWheel($event)">
								<span class="showInput" @click="inputFocus($event)">
									{{judgingCondition('dilutedEps',i)?method.formateNum(profit.dilutedEps[i],2):'-'}}
								</span>
							</span>
						</li>
					</ul>

					<p class="line"></p>
				</div>

			</div>

		</div>

	</div>
</template>

<script>
	import common from '@/views/common'
	import qs from 'qs'
	import axios from 'axios';

	import {
		mapState,
		mapMutations
	} from 'vuex'

	export default {
		mixins: [common],
		props: {
			stage: {
				type: [String, Number]
			},
			type: {
				type: [String, Number]
			},
			versions: {
				type: [String, Number],
				required: false
			},
			url: String,
			jumpAddress: String //保存之后的跳转地址

		},
		data() {
			return {
				yearList: ['2024年', '2024年'],
				id: '',
				isCacheValue: '',
				isLoan: [], //当基准日资产负债表中短期借款或长期借款科目有数据时，利润表中财务费用-利息支出数据不可为零
				profit: {
					income: [],
					cost: [],
					taxAdditional: [],
					saleCost: [],
					manageCost: [],
					researchCost: [],
					financeCost: [],
					interestCost: [],
					interestIncome: [],
					otherBenefits: [],
					investmentIncome: [],
					enterpriseIncome: [],
					financialAssetsBenefits: [],
					netExposureHedgeIncome: [],
					fairValueIncome: [],
					creditImpairmentLoss: [],
					assetLoss: [],
					assetDisposalIncome: [],
					businessProfit: [],
					outsideIncome: [],
					outsideExpenditure: [],
					totalProfit: [],
					incomeTax: [],
					netProfit: [],
					goingConcernNetProfit: [],
					discontinuedOperationsNetProfit: [],
					parentCompanyNetProfit: [],
					minorityStockLegalRight: [],
					totalComprehensiveIncome: [],
					parentCompanyTci: [],
					minorityStockTci: [],
					ociNetTax: [],
					parentCompanyOciNetTax: [],
					notReclassifiedOci: [],
					remeasureBenefitAmount: [],
					notTransferOci: [],
					otherEquityFairValue: [],
					enterpriseCreditRiskFairValue: [],
					reclassifiedOci: [],
					transferOci: [],
					otherClaimsFairValue: [],
					financialAssetsOciAmount: [],
					otherClaimsCreditImpairment: [],
					cashFlowHedgingReserve: [],
					foreignCurrencyBalance: [],
					minorityStockOciNetTax: [],
					basicEps: [],
					dilutedEps: []

				},
				isSave: false

			}
		},
		mounted() {
			this.getProfit()
			this.pageModify('false');
		},
		methods: {
			...mapMutations(['pageModify']),
			change() {
				this.pageModify('true');
				if (!this.$route.query.reportId || (this.$route.query.reportId && this.isCacheValue == '0') || (this.$route
						.query.reportId && !this.id)) {
					this.method.caching("profitInfo", JSON.stringify(this.profit), this.type, this.stage, this)
				}
			},
			inputFocus: function(e) {
				this.isSave = false
				e.currentTarget.style.display = 'none';
				e.currentTarget.previousSibling.style.display = 'block';
				e.currentTarget.previousSibling.focus();
			},
			focus(e) {
				e.currentTarget.setAttribute("placeholder", "")

			},
			judgingCondition: function(key, i, index) {
				if (this.profit[key][i] === 0) {
					return true
				} else if (this.profit[key][i] != 0 && this.profit[key][i] != '-') {
					return true
				} else {
					return false
				}
			},
			blur: function(e, data, name, num) { //data：资产负债表的字段   name：具体的科目名称   num：科目的具体下标
				this.isSave = false
				e.currentTarget.nextSibling.style.display = 'block';
				var y = String(data[name][num]).indexOf("."); //获取小数点的位置
				var count = String(data[name][num]).length - y; //获取小数点后的个数
				this.pageModify('true');
				if (data[name][num]) {
					if (String(data[name][num]).indexOf(".") != -1) {
						this.$set(this.profit[name], num, Math.round(Number(data[name][num]) * 100) / 100) //保留两位
					} else {
						if (data[name][num].length > 20) { //判断数据的合理性
							this.$Message.error({
								background: true,
								content: '请输入合理数据'
							});
							this.$set(this.profit[name], num, '')
						} else {
							this.$set(this.profit[name], num, Number(data[name][num]))
						}
					}
				} else {
					this.$set(this.profit[name], num, '')
				}

				for (var i = 0; i < this.yearList.length; i++) {
					// 二、营业利润(亏损以"-"号填列)
					this.profit.businessProfit[i] = Number(this.profit.income[i]) - Number(this.profit.cost[i]) -
						Number(this.profit.taxAdditional[i]) - Number(this.profit.saleCost[i]) - Number(this.profit
							.manageCost[i]) - Number(this.profit.researchCost[i]) - Number(this.profit.financeCost[
							i]) + Number(this.profit.otherBenefits[i]) + Number(this.profit.investmentIncome[i]) +
						Number(this.profit.netExposureHedgeIncome[i]) + Number(this.profit.fairValueIncome[i]) +
						Number(this.profit.creditImpairmentLoss[i]) + Number(this.profit.assetLoss[i]) + Number(this
							.profit.assetDisposalIncome[i]);

					// 三、利润总额（亏损总额以“-”号填列)
					this.profit.totalProfit[i] = Number(this.profit.businessProfit[i]) + Number(this.profit.outsideIncome[i]) - Number(this.profit.outsideExpenditure[i]);

					// 四、净利润(净亏损以"-"号填列)
					this.profit.netProfit[i] = Number(this.profit.totalProfit[i]) - Number(this.profit.incomeTax[i]);

					// 1.不能重分类进损益的其他综合收益
					this.profit.notReclassifiedOci[i] = Number(this.profit.remeasureBenefitAmount[i]) + Number(this
						.profit.notTransferOci[i]) + Number(this.profit.otherEquityFairValue[i]) + Number(this
						.profit.enterpriseCreditRiskFairValue[i]);

					// 	// 2.将重分类进损益的其他综合收益
					this.profit.reclassifiedOci[i] = Number(this.profit.transferOci[i]) + Number(this.profit
							.otherClaimsFairValue[i]) + Number(this.profit.financialAssetsOciAmount[i]) + Number(this
							.profit.otherClaimsCreditImpairment[i]) + Number(this.profit.cashFlowHedgingReserve[i]) +
						Number(this.profit.foreignCurrencyBalance[i]);

					// (一)归属于母公司所有者的其他综合收益的税后净额
					this.profit.parentCompanyOciNetTax[i] = Number(this.profit.notReclassifiedOci[i]) +
						Number(this.profit.reclassifiedOci[i]);

					// 五、其他综合收益的税后净额
					this.profit.ociNetTax[i] = Number(this.profit.parentCompanyOciNetTax[i]) + Number(this.profit
						.minorityStockOciNetTax[i]);

					// 六、综合收益总额
					this.profit.totalComprehensiveIncome[i] = Number(this.profit.netProfit[i]) + Number(this.profit
						.ociNetTax[i]);

					// 	// (一)归属于母公司所有者的综合收益总额
					this.profit.parentCompanyTci[i] = Number(this.profit.parentCompanyNetProfit[i]);

					// 	// (二)归属于少数股东的综合收益总额
					this.profit.minorityStockTci[i] = Number(this.profit.minorityStockLegalRight[i]);

				}

				//调用缓存共用方法
				if (!this.$route.query.reportId || (this.$route.query.reportId && this.isCacheValue == '0') || (this.$route
						.query.reportId && !this.id)) {
					this.method.caching("profitInfo", JSON.stringify(this.profit), this.type, this.stage, this)
				}


			},
			getProfit: function(close) {
				this.$Spin.show(); //全局加载中
				var this_ = this;

				this.$post(this.url + 'financeProfitInfo/qryProfitInfo', qs.stringify({
						reportId: this.$route.query.reportId ? this.$route.query.reportId : '',
						reportType: this.type,
						versions: this.versions,
						stage: this.stage,
					}))
					.then((res) => {
						this.$Spin.hide();
						var profitKey = Object.keys(this.profit); //获取利润表对象的 key

						this.yearList = res.data.content.year;
						this.id = res.data.content.id;
						this.isCacheValue = res.data.content.isCache

						if (res.data.content.isLoan) {
							this.isLoan = res.data.content.isLoan;
						}

						if (res.data.content.profit) {
							this.profit = JSON.parse(res.data.content.profit)

							if (res.data.content.isCache == "0" && !sessionStorage.isHistoryCache) {
								this.showDialogBar(true, 'cacheType', '您上次填写过估值报告，是否载入缓存信息？')

								// 清除 0.00
								var dataKey = Object.keys(this.profit); //获取对象的 key
								dataKey.forEach((key, idx) => {
									this.yearList.forEach((item, i) => {
										this.profit[key].push("")
									})
								})

							} else { //正式数据
								var returnProfit = Object.keys(JSON.parse(res.data.content
									.profit)) //后台数据返回的利润表对象的 key
								profitKey.forEach((elem, index) => {
									returnProfit.forEach((item, i) => {
										this_.yearList.forEach((yearItem, y) => {
											if (elem == item) {
												this_.profit[elem][y] = JSON.parse(res.data
													.content.profit)[elem][y];
											} else if (!this_.profit[elem]) {
												if (this_.yearList.length == 2) {
													this_.profit[elem] = ['', '']
												} else if (this_.yearList.length == 3) {
													this_.profit[elem] = ['', '', '']
												}
											}
										})
									})
								})
							}
						} else {
							var dataKey = Object.keys(this.profit); //获取对象的 key
							dataKey.forEach((key, idx) => {
								this.yearList.forEach((item, i) => {
									this.profit[key].push("")
								})
							})
						}

					});

			},
			save() {
				var this_ = this


				if (this.profit.interestCost[0] == 0 && this.isLoan[0] == true) {
					this.showDialogBar(true, 'notEmpty', this.yearList[0] + '利息支出数据不可为零')
					return false;
				}

				if (this.profit.interestCost[1] == 0 && this.isLoan[1] == true) {
					this.showDialogBar(true, 'notEmpty', this.yearList[1] + '利息支出数据不可为零')
					return false;
				}
				
				if (!this.profit.income[1] && !this.profit.cost[1] && !this.profit.taxAdditional[1] && !this.profit
					.saleCost[1] && !this.profit.manageCost[1] && !this.profit.researchCost[1] && !this.profit.financeCost[
						1] && !this.profit.incomeTax[1] && !this.profit.businessProfit[1] && !this.profit.totalProfit[1] && !
					this.profit.netProfit[1]) {
					// 一、营业收入、减:营业成本、税金及附加、销售费用、管理费用、研发费用、财务费用、二、营业利润(亏损以"-"号填列)、三、利润总额（亏损总额以“-”号填列)、减:所得税费用、四、净利润(净亏损以"-"号填列) 数据不能同时为零
					this.showDialogBar(true, 'notEmpty', this.yearList[1] + '的数据不能全部为零')
					return false;
				} 


				if (!this.isSave) {
					this.isSave = true
					this.$post(this.url + 'financeProfitInfo/save', qs.stringify({
							stage: this.stage,
							reportType: this.type,
							id: this.id ? this.id : '',
							reportId: this.$route.query.reportId ? this.$route.query.reportId : '',
							profitInfo: JSON.stringify(this.profit),
							lastProfit: this.profit.netProfit[1] //最后一年的净利润
						}))
						.then((res) => {
							if (res.data.code == 200) {
								setTimeout(function() {
									this_.$router.push({
										name: this_.jumpAddress,
										query: {
											reportId: res.data.content.valuationReportId
										}
									})
								}, 500)

							}
						})
				}

			},
			//是  载入缓存
			clickCacheTrueMaskJump() {
				sessionStorage.isHistoryCache = 1;
				this.getProfit();
				this.hideDialogBar()
			},

			//否  载入缓存
			clickCacheMaskJump() {
				sessionStorage.isHistoryCache = 1;
				this.method.clearCaching(this.type, this.stage, this, 'getProfit')
				this.hideDialogBar()
			},
			closeMaskInfoJump() {
				this.hideDialogBar()
			}




		}


	}
</script>

<style>
</style>